.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-attachment: scroll;
  padding: 3.9px 45px;
  background-color: var(--bg-footer);
}

.container {
  display: flex;
  justify-content: space-around;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 8px;

  h1 {
    font-size: 1rem;
    color: var(--txt);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.copyright {
  flex: 1;
  text-align: center;
}

.body {
  flex: 1;
  text-align: center
}

.body li {
  padding: .2rem 1rem;
  font-size: 1.2rem;
  color: var(--txt);
  cursor: pointer;
}

.socialIcons {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  list-style: none;
  padding: 0
}

.socialIcons {
  color: var(--txt)
}
