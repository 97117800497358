.layout {
  background: var(--bg-layout-base);
  color: var(--txt);
  height: 100%;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  min-height: 100vh;
}