.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  z-index: 11;
  background-color: var(--bg-modal);
  border: var(--modal-border);
  border-radius: .25rem;
  color: var(--txt);
}

.closeWrapper {
  position: relative;
  z-index: 150;
  width: 100%;
  height: 30px;
  margin-top: -30px;
  bottom: -30px;
  display: flex;
}

.closeIcon {
  color: var(--modal-close-icon);
  position: relative;
  right: -3px;
  top: -1px;
  stroke-width: 4px;
  transition: all 0.4s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

.closeButton {
  margin-left: auto;
  right: 0;
  background-color: var(--modal-close);
  border-radius: 0 0 0 100%;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.08);
  width: 45px;
  height: 45px;
  padding: 0;
}

@media (max-height: 575px) {
  .body {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
