.content {
  padding: 9rem 9rem 3rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding-top: 10px;
  color: var(--txt);
  font-size: 6rem;
  font-weight: 200;
}

.box-container {
  /* Add a new class for the box container */
  max-width: 600px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the box container */
  padding: 20px; /* Add padding to the box container */
  background-color: #fff; /* Set background color for the box container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a box shadow for styling */
}

.form {
  margin-top: 2rem;
}

.formGroup {
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--txt);
    font-size: 1rem;
  }

  input,
  textarea {
    width: calc(100% - 1rem); /* Make the input fields smaller */
    padding: 0.3rem; /* Adjust as needed */
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.submitText {
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .header {
    padding-bottom: 10px;
  }
  .title {
    font-size: 3rem;
  }

  .form {
    margin-top: 1rem;
  }
}

@media (max-width: 925px) {
  .content {
    padding: 9rem 3rem 1rem;
  }
  .title {
    font-size: 4rem;
  }
}
