.header {
  position: fixed;
  z-index: 130;
  width: 100%;
  background-attachment: scroll;
  padding: 3.9px 26px;
  transition: all 0.3s ease-out 0s;
  text-overflow: clip;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.highlighted {
  background-color: var(--bg-header);
  outline: rgb(33, 37, 41) dashed 0;
  box-shadow: rgba(9, 5, 29, 0.173) 0 10px 10px 0;
  backdrop-filter: blur(15px);
}

@media (max-width: 768px) {
  .header {
    background-color: var(--bg-primary);
    backdrop-filter: none;
    padding: 10px 20px;
  }
  .container {
    justify-content: space-between;
  }
}